import React, { useEffect, useMemo, useState } from 'react';
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  orderStatus,
  typeOfOrder,
  statusLabels,
  packageTypeLabels,
  paymentPerson,
  debtPaidStatus,
  paymentMethodd,
} from '../../../../utils/index';
import {
  Stack,
  Button,
  Tooltip,
  MenuItem,
  TextField,
  IconButton,
} from '@mui/material';
import { Title } from '../../../../components/Title/Title';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import { Header } from '../../../../components/Header/Header';
import { Link, useNavigate } from 'react-router-dom';
import { citiesRef, completedOrdersRef, orderRef, userCollectionRef } from '../../../../utils/collectionRefs';
import OrderSort from '../../../../components/OrderSort';
import { dateFormatter } from '../../../../utils/dateFormatter';
import { headersForCompletedOrders } from './constants';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import OrderGrid from '../../../../components/OrderGrid ';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import BalanceCard from '../../../../components/BalanceCard/BalanceCard';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import { Helmet } from 'react-helmet';
import './scss/Orders.scss';
import axios from 'axios';
import { js2xml } from 'xml-js';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';

const ExportToExcelButton = ({ ordersData }) => {
  const headers = [...headersForCompletedOrders, 'Долг'];

  const exportToExcel = () => {
    const data = ordersData.map((order) => [
      dateFormatter(order.dateCreated?.seconds),
      dateFormatter(order.dateDelivered?.seconds),
      !order.pid ? 'Отсутствует' : order.pid,
      !order.courierOne ? 'Отсутствует' : order.courierOne,
      !order.courierTwo ? 'Отсутствует' : order.courierTwo,
      statusLabels[order.status],
      packageTypeLabels[order.packageType],

      order.addressFrom?.cityName || null,
      `${order.senderName}, (${order.sender})`,

      order.addressTo?.cityName || null,
      `${order.receiverName},(${order.receiverPhone})`,

      !order.cost ? `${order.tariff?.cost}` : `${order.cost}`,
      `${order?.redemption}`,
      (() => {
        switch (order?.whoPays) {
          case 1:
            return 'Отправитель';
          case 2:
            return 'Получатель';
          default:
            return 'Совместно';
        }
      })(),
      `${order?.senderPays === null ? '0.0' : order?.senderPays}`,
      `${!order?.debtpaid ? 'Не оплачен' : 'Оплачен'}`,
    ]);

    const finalData = [headers, ...data];
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    ws['!cols'] = Array(finalData[0].length).fill({ wch: 22 });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Список заказов с долгами');

    XLSX.writeFile(wb, 'Заказы.xlsx');
  };

  return (
    <Button variant='black-contained' onClick={exportToExcel}>
      Экспорт в Excel
    </Button>
  );
};

const OrdersDebt = ({ pageTitle }) => {
  const [orders, setOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDate2, setSelectedDate2] = useState('');
  const [cities, setCities] = useState(null);
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    cityTo: '',
    cityFrom: '',
    packageType: '',
    status: '',
    whoPays: '',
  });
  const navigate = useNavigate();
  const userType = localStorage.getItem('userType');
  const userName = localStorage.getItem('userName');
  const accountType = localStorage.getItem('roleType');
  const SMS_API = process.env.REACT_APP_SMS_API_URL

  useEffect(() => {
    let q = completedOrdersRef;

    Object.keys(selectedFilters).forEach((field) => {
      const value = selectedFilters[field];

      if (value !== 'Все' && value !== '') {
        q = query(q, where(field, '==', value));
      }
    });

    if (selectedDate && selectedDate2) {
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate2);
      endOfDay.setHours(23, 59, 59, 999);

      q = query(
        q,
        where('dateCreated', '>=', startOfDay),
        where('dateCreated', '<=', endOfDay)
      );
    }

    if (
      !selectedFilters.dateCreated ||
      selectedFilters.dateCreated === 'Все' ||
      selectedFilters.dateCreated === ''
    ) {
      q = query(q, where('redemption', '<', 0), orderBy('redemption'));
    }


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newOrders = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setOrders(newOrders);
    }, (error) => {
      console.error('Error fetching real-time orders:', error);
    });


    return () => unsubscribe();
  }, [selectedFilters, selectedDate, selectedDate2]);

  const fetchDocuments = async (collectionRef, sortField, sortOrder) => {
    try {
      const querySnapshot = await getDocs(
        query(collectionRef, orderBy(sortField, sortOrder))
      );
      return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    } catch (error) {
      console.error('Error fetching documents:', error);
      return [];
    }
  };

  const fetchCities = async () => {
    const data = await fetchDocuments(citiesRef, 'name', 'asc');
    setCities(data);
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const sortOrderBy = (field, value) => {
    setSelectedFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const clearFilter = () => {
    window.location.reload();
  };

  const createOrderSortHandler = (field) => (event) =>
    sortOrderBy(field, event.target.value);

  const orderCityToSort = createOrderSortHandler('cityTo');
  const orderCityFromSort = createOrderSortHandler('cityFrom');
  const orderTypeSort = createOrderSortHandler('packageType');
  const orderStatusSort = createOrderSortHandler('status');
  const orderWhoPaysSort = createOrderSortHandler('whoPays');
  const orderDebtPaidSort = createOrderSortHandler('debtpaid');
  const orderPaymentMethodSort = createOrderSortHandler('paymentMethod');

  const handleDateChange = (field, value) => {
    if (field === 'selectedDate') {
      setSelectedDate(value);
    } else if (field === 'selectedDate2') {
      setSelectedDate2(value);
    }
  };

  const handleDateChange1 = (e) =>
    handleDateChange('selectedDate', e.target.value);
  const handleDateChange2 = (e) =>
    handleDateChange('selectedDate2', e.target.value);



  const getStatusClass = (status) => {
    switch (status) {
      case 'status_new':
        return 'orders-card-values status_order new-order';
      case 'status_confirmed':
        return 'orders-card-values status_order confirmed-order';
      case 'status_arrived_sender':
        return 'orders-card-values status_order arrived-sender-order';
      case 'status_on_courier':
        return 'orders-card-values status_order on-courier-order';
      case 'status_at_sorting_center':
      case 'status_at_sorting_center2':
        return 'orders-card-values status_order at-sorting-order';
      case 'status_on_way_cc':
      case 'status_on_courier2':
        return 'orders-card-values status_order on-way';
      case 'status_arrived_receiver':
        return 'orders-card-values status_order arrived-receiver-order';
      case 'status_delivered':
        return 'orders-card-values status_order delivered-order';
      case 'status_rejected':
        return 'orders-card-values status_order rejected-order';
      case 'status_cancelled':
        return 'orders-card-values status_order cancelled-order';
      default:
        return '';
    }
  };

  const handleSearchOrder = (e) => {
    setSearch(e.target.value.toLowerCase().trim());
  };

  const filteredOrder = useMemo(
    () =>
      orders?.filter((order) => {
        const {
          id,
          pid,
          receiver,
          sender,
          senderName,
          courierOne,
          courierTwo,
        } = order;

        return (
          (id && id.toLowerCase().includes(search)) ||
          (pid && pid.toLowerCase().includes(search)) ||
          (receiver && receiver.toLowerCase().includes(search)) ||
          (sender && sender.toLowerCase().includes(search)) ||
          (senderName && senderName.toLowerCase().includes(search)) ||
          (courierOne && courierOne.toLowerCase().includes(search)) ||
          (courierTwo && courierTwo.toLowerCase().includes(search))
        );
      }),
    [orders, search]
  );

  const calculateTotalBalance = () => {
    if (!filteredOrder || !filteredOrder.length) return 0;
    const orderCosts = filteredOrder.map((order) => order.cost || 0);
    const totalBalance = orderCosts.reduce((total, cost) => total + cost, 0);
    return totalBalance;
  };

  const calculateTotalDebts = () => {
    if (!filteredOrder) return 0;

    const unpaidOrders = filteredOrder.filter(order => !order.debtpaid);

    const debts = unpaidOrders
      .map((order) => order?.redemption || 0)
      .filter((balance) => balance < 0);
    return debts.reduce((total, debt) => total + debt, 0);
  };

  const calculateTotalRedemption = () => {
    if (!filteredOrder) return 0;

    const positiveBalances = filteredOrder
      .map((order) => order?.redemption || 0)
      .filter((balance) => balance > 0);

    return positiveBalances.reduce((total, balance) => total + balance, 0);
  };

  const createXmlData = ({ id, text, phone }) => {
    return js2xml({
      elements: [
        {
          type: 'instruction',
          name: 'xml',
          instruction: 'version="1.0" encoding="UTF-8"'
        },
        {
          type: 'element',
          name: 'message',
          elements: [
            { type: 'element', name: 'login', elements: [{ type: 'text', text: 'Aylex' }] },
            { type: 'element', name: 'pwd', elements: [{ type: 'text', text: 'gMaKpOi_' }] },
            { type: 'element', name: 'id', elements: [{ type: 'text', text: id }] },
            { type: 'element', name: 'sender', elements: [{ type: 'text', text: 'AylEx.kg' }] },
            { type: 'element', name: 'text', elements: [{ type: 'text', text }] },
            {
              type: 'element',
              name: 'phones',
              elements: [
                {
                  type: 'element',
                  name: 'phone',
                  elements: [{ type: 'text', text: phone }]
                }
              ]
            }
          ]
        }
      ]
    });
  };

  const sendXmlRequest = async (xmlData) => {
    return axios.post(SMS_API, xmlData, {
      headers: {
        'Content-Type': 'application/xml',
        'Accept': 'application/xml'
      }
    });
  };

  const handlePayDebt = async (data) => {
    const fullDescription = `${userType} ${userName} оплатил долг за заказ ID:(${data?.id})`;

    while (!data?.id) {
      await new Promise(resolve => setTimeout(resolve, 500)); // Ждем 500 мс перед повторной проверкой
    }
    const orderHistoryRef = collection(orderRef, data?.id, 'history')

    try {
      await updateDoc(doc(completedOrdersRef, data?.id), { debtpaid: true });

      const xmlData = createXmlData(data);

      await sendXmlRequest(xmlData);
      await addDoc(orderHistoryRef, {
        date: new Date(),
        description: fullDescription,
      });
      await addDoc(userCollectionRef, {
        date: new Date(),
        description: fullDescription
      })
      handleClickClose();
      toast.success('Долг успешно погашен');
    } catch (error) {
      console.error('Ошибка при оплате долга:', error);
      toast.error('Ошибка при оплате долга');
    }
  };

  const handleClickOpen = (data) => {
    setOpen(true);
    handlePayDebt(data);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const actionsConfig = [
    {
      title: 'История',
      icon: <AccessTimeFilledRoundedIcon style={{ color: '#000b1f' }} />,
      handler: (params) => navigate(`/order/history/orders/${params.id}`),
    },
  ];

  const ActionIconButton = ({ title, icon, onClick }) => (
    <Tooltip title={title} arrow>
      <IconButton color='primary' aria-label='edit' onClick={onClick}>
        {React.cloneElement(icon, { style: { color: '#000b1f' } })}
      </IconButton>
    </Tooltip>
  );

  const columns = [
    {
      field: 'details',
      headerName: '',
      width: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title='Детали' arrow >
          <Link to={`/order/completed/details/${params.id}`} target='_blank'>
            <IconButton color='primary' aria-label='edit'>
              <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
            </IconButton>
          </Link>
        </Tooltip>
      )
    },
    {
      width: 140,
      field: 'dateCreated',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params?.row?.dateCreated?.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата создания</strong>,
      disableColumnMenu: false,
    },
    {
      width: 140,
      field: 'dateDelivered',
      sortable: true,
      renderCell: (params) => {
        return <div>{dateFormatter(params?.row?.dateDelivered?.seconds)}</div>;
      },
      renderHeader: () => <strong>Дата доставки</strong>,
      disableColumnMenu: false,
    },
    {
      width: 200,
      field: 'pid',
      sortable: false,
      renderHeader: () => <strong>Номер заказа</strong>,
      renderCell: (params) => {
        return <div>{!params.row.pid ? 'Отсутствует' : params.row.pid}</div>;
      },
    },
    {
      width: 120,
      field: 'courierOne',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierOne ? 'Отсутствует' : params.row.courierOne}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер</strong>,
    },
    {
      width: 120,
      field: 'courierTwo',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div>
            {!params.row.courierTwo ? 'Отсутствует' : params.row.courierTwo}
          </div>
        );
      },
      renderHeader: () => <strong>Курьер 2</strong>,
    },
    {
      width: 180,
      field: 'status',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        const statusClassName = getStatusClass(params.row.status);
        return (
          <strong className={statusClassName}>
            {statusLabels[params.row.status]}
          </strong>
        );
      },
      renderHeader: () => <strong>Статус</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      field: 'packageType',
      sortable: false,
      renderCell: (params) => {
        return <div>{packageTypeLabels[params.row.packageType]}</div>;
      },
      renderHeader: () => <strong>Тип товара</strong>,
    },
    {
      width: 200,
      field: 'addressFrom',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.addressFrom.cityName}</span>
            <span> {params.row.addressFrom.address}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Откуда</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 154,
      field: 'sender',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.senderName}</span>
            <span> {params.row.sender}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Отправитель</strong>,
    },
    {
      width: 200,
      field: 'addressTo',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.addressTo.cityName}</span>
            <span> {params.row.addressTo.address}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Куда</strong>,
    },
    {
      width: 154,
      field: 'receiver',
      sortable: false,
      renderCell: (params) => {
        return (
          <div className='orders-card-values client'>
            <span> {params.row.receiverName}</span>
            <span> {params.row.receiver}</span>
          </div>
        );
      },
      renderHeader: () => <strong>Получатель</strong>,
    },
    {
      width: 80,
      field: 'cost',
      sortable: false,
      headerAlign: 'center',
      renderCell: (params) => {
        return <div>{params.row.cost}с</div>;
      },
      renderHeader: () => <strong>Сумма</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 100,
      headerAlign: 'center',
      field: 'redemption',
      sortable: false,
      renderCell: (params) => {
        return <div>{params.row.redemption}с</div>;
      },
      renderHeader: () => <strong>Выкуп</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      field: 'whoPays',
      sortable: false,
      renderCell: (params) => {
        let whoPaysText;
        switch (params.row.whoPays) {
          case 1:
            whoPaysText = 'Отправитель';
            break;
          case 2:
            whoPaysText = 'Получатель';
            break;
          default:
            whoPaysText = 'Совместно';
        }
        return <div>{whoPaysText}</div>;
      },
      renderHeader: () => <strong>Кто оплачивает</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 130,
      headerAlign: 'center',
      field: 'senderPays',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.senderPays === null ? 0.0 : params?.row?.senderPays}
          </div>
        );
      },
      renderHeader: () => <strong>SenderPays</strong>,
      cellClassName: 'centered-cell',
    },
    {
      width: 200,
      field: 'requisite',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params?.row?.requisite}
          </div>
        );
      },
      renderHeader: () => <strong>Реквизиты</strong>,
      cellClassName: 'centered-cell',
    },
    {
      field: 'debt',
      sortable: false,
      width: 120,
      headerAlign: 'center',
      menubar: false,
      renderCell: (params) => {
        const id = params?.row?.id
        const redemption = params?.row?.redemption
        const phone = params?.row?.sender
        const text = `Ваш долг за заказ #(${params?.row?.pid}) оплачен. ${redemption} сом`

        const data = { id, text, phone }

        return (
          <>
            {!params?.row?.debtpaid ? (
              <Tooltip title={accountType === 'manager' ? 'Менеджеры не могут оплачивать' : 'Погасить долг'} arrow>
                <strong
                  className='orders-card-values status_order balanceBefore'
                  style={{ cursor: accountType === 'manager' ? 'not-allowed' : 'pointer' }}
                  onClick={accountType !== 'manager' ? () => handleClickOpen(data) : undefined}
                >
                  Оплатить
                </strong>
              </Tooltip>
            ) : (
              <strong className='orders-card-values status_order new-order'>
                Оплачен
              </strong>
            )}

          </>
        )
      },
      renderHeader: () => <strong>Долг</strong>,
    },
    {
      field: 'actions',
      sortable: false,
      width: 120,
      headerAlign: 'center',
      menubar: false,
      renderCell: (params) => {
        const id = params?.row?.id
        return (
          <>
            <Tooltip title='Детали' arrow>
              <Link to={`/order/completed/details/${id}`} target='_blank'>
                <IconButton color='primary' aria-label='edit'>
                  <RemoveRedEyeRoundedIcon style={{ color: '#000b1f' }} />
                </IconButton>
              </Link>
            </Tooltip>
            {actionsConfig.map(
              (action, index) =>
                action.visible !== false && (
                  <ActionIconButton
                    key={index}
                    title={action.title}
                    icon={action.icon}
                    onClick={() => action.handler(params)}
                  />
                )
            )}
          </>
        )
      },
      renderHeader: () => <strong>Действия</strong>,
    },

  ];

  return (
    <>
      <div className='containerr'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        <Header previous='Статистика' initial='Задолженность' />
        <Title
          title='Список заказов с долгами'
          icon={<AssignmentRoundedIcon fontSize='24' />}
        />
        <div className='container-inner'>
          <div className='order-statistics-container order-statistics-container-two'>
            <BalanceCard
              title='Общее количество заказов'
              value={!filteredOrder ? 0 : filteredOrder?.length}
              icon={ListAltRoundedIcon}
              iconColor='#850e90'
              borderColor='#850e90'
            />
            <BalanceCard
              title='Общая сумма заказов'
              value={calculateTotalBalance()}
              icon={MonetizationOnRoundedIcon}
              iconColor='#10B981'
              borderColor='#10B981'
            />
            <BalanceCard
              title='Общая сумма выкупов'
              value={calculateTotalRedemption()}
              icon={CurrencyExchangeRoundedIcon}
              iconColor='#3A46D6'
              borderColor='#3A46D6'
            />
            <BalanceCard
              title='Общая сумма долгов'
              value={calculateTotalDebts()}
              icon={CurrencyExchangeRoundedIcon}
              iconColor='#f70303'
              borderColor='#f70303'
            />
          </div>

          <div className='orders-sorting-header clear-btn'>
            <Button variant='black-contained' onClick={clearFilter}>
              Очистить
            </Button>
            <Stack
              sx={{ width: '100%', flexWrap: 'wrap' }}
              direction='row'
              gap={2}
            >
              <OrderSort
                sortKey='По типу'
                defaultValue=''
                options={typeOfOrder}
                onChange={orderTypeSort}
              />
              <OrderSort
                sortKey='Кто оплачивает'
                defaultValue=''
                options={paymentPerson}
                onChange={orderWhoPaysSort}
              />
              <OrderSort
                sortKey='По методу оплату'
                defaultValue=''
                options={paymentMethodd}
                onChange={orderPaymentMethodSort}
              />

              <OrderSort
                sortKey='Долг'
                defaultValue=''
                options={debtPaidStatus}
                onChange={orderDebtPaidSort}
              />

              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Откуда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityFromSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className='order-sort'>
                <TextField
                  id='outlined-select-currency'
                  select
                  label='Куда'
                  fullWidth
                  size='small'
                  defaultValue=''
                  onChange={orderCityToSort}
                >
                  <MenuItem value='Все'>Все</MenuItem>
                  {cities?.map((option) => (
                    <MenuItem key={option.name} value={parseInt(option.id)}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <OrderSort
                sortKey='По статусу'
                defaultValue=''
                options={orderStatus}
                onChange={orderStatusSort}
              />
              <div className='order-sort order-sort-date'>
                <label htmlFor='orderDate' className='orders-sort-date-label'>
                  От
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate'
                    id='orderDate'
                    value={selectedDate || ''}
                    onChange={handleDateChange1}
                  />
                </label>
                <span></span>
                <label htmlFor='orderDate2' className='orders-sort-date-label'>
                  До
                  <input
                    className='order-sort-dateInput'
                    type='date'
                    name='orderDate2'
                    id='orderDate2'
                    value={selectedDate2 || ''}
                    onChange={handleDateChange2}
                  />
                </label>
              </div>
            </Stack>
          </div>
          <div className='orders-card-wrapper'>
            <div className='orders-card-header'>
              <div className='orders-card-parent'>
                <div className='orders-card-parent__btns'>
                  {userType === 'Админ' && (
                    <Button variant='black-contained' onClick={() => navigate('/order/add')}>
                      Новый заказ +
                    </Button>
                  )}
                  <ExportToExcelButton ordersData={orders} />
                </div>
                <div style={{ width: '49%' }}>
                  <SearchInput
                    label='Поиск'
                    placeholder='Введите'
                    variant='outlined'
                    onChange={handleSearchOrder}
                  />
                </div>
              </div>
            </div>
            <OrderGrid
              orders={filteredOrder || orders}
              columns={columns}
              size={20}
            />
          </div>
        </div>
      </div>
      <CustomDialog
        open={open}
        onClose={handleClickClose}
        event={() => handlePayDebt()}
        text='Вы уверены что хотите погасить долг?'
        btnText='Погасить'
      />
    </>
  );
};

export default OrdersDebt;
